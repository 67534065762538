import { Injectable } from '@angular/core';
import { ScriptLoaderService } from '@nowffc-shared/services/script-loader/script-loader.service';
import { WindowRef } from '@nowffc-shared/services/window/window';

type LoyJoyEventCallback = (type: string, details: unknown) => void;

@Injectable({
  providedIn: 'root',
})
export class LoyjoyService {
  constructor(
    private readonly scriptLoaderService: ScriptLoaderService,
    private readonly windowRef: WindowRef,
  ) {}

  /**
   * the minimum viewport width for the bot
   * */
  readonly minViewPortWidth = 1000;

  show() {
    if (this.windowRef.nativeWindow.LoyJoy) {
      this.windowRef.nativeWindow.LoyJoy('show');
    }
  }

  open() {
    if (this.windowRef.nativeWindow.LoyJoy) {
      this.windowRef.nativeWindow.LoyJoy('open');
    }
  }

  close() {
    if (this.windowRef.nativeWindow.LoyJoy) {
      this.windowRef.nativeWindow.LoyJoy('close');
    }
  }

  remove() {
    if (this.windowRef.nativeWindow.LoyJoy) {
      this.windowRef.nativeWindow.LoyJoy('remove');
    }
  }

  hide() {
    if (this.windowRef.nativeWindow.LoyJoy) {
      this.windowRef.nativeWindow.LoyJoy('hide');
    }
  }

  #isOpen: boolean = false;
  get isOpen(): boolean {
    return this.#isOpen;
  }

  boot(
    processId: string,
    params: object,
    openBot: boolean,
    eventCallback?: LoyJoyEventCallback,
    getParamsCallback?: object,
  ) {
    if (this.windowRef.nativeWindow.LoyJoy) {
      this.windowRef.nativeWindow.LoyJoy('boot', {
        open: openBot,
        params,
        process: processId,
        eventListeners: [
          eventCallback,
          (event: string) => {
            switch (event) {
              case 'open':
                this.#isOpen = true;
                break;
              case 'close':
                this.#isOpen = false;
                break;
            }
          },
        ],
        callbacks: getParamsCallback,
      });
    }
  }

  async init(
    processId: string,
    params: object,
    openBot = false,
    showInMobile = false,
    eventCallback?: LoyJoyEventCallback,
    getParamsCallback?: object,
  ): Promise<boolean> {
    if (!this.viewportRequirementsMet() && !showInMobile) {
      return false;
    }
    return this.scriptLoaderService.loadScript('loyJoy').then((isLoaded) => {
      if (isLoaded) {
        this.boot(processId, params, openBot, eventCallback, getParamsCallback);
      }
      return isLoaded;
    });
  }

  public loadLoyJoy(
    processId: string,
    openBotOnInit = false,
    showInMobile = false,
    eventCallback: LoyJoyEventCallback,
    getParamsCallback?: object,
  ): void {
    this.init(
      processId,
      {},
      openBotOnInit,
      showInMobile,
      eventCallback,
      getParamsCallback,
    );
  }

  viewportRequirementsMet() {
    return this.windowRef.nativeWindow.innerWidth >= this.minViewPortWidth;
  }

  isInitialized() {
    return !!this.windowRef.nativeWindow.LoyJoy;
  }
}
