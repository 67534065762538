import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgForOf } from '@angular/common';
import { Router } from '@angular/router';
import { ConsentService } from '@nowffc-shared/services/tracking/consent.service';

@Component({
  standalone: true,
  selector: 'now-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  imports: [TranslateModule, NgForOf],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  currentYear = new Date().getFullYear();

  entries: {
    title: string;
    links: { name: string; href: string; onClickHandler?: any }[];
  }[] = [
    {
      title: 'Services',
      links: [
        {
          name: 'Hilfe & Kontakt',
          href: 'https://community.plus.rtl.de/s',
          onClickHandler: () => {},
        },
        {
          name: 'Impressum',
          href: 'https://www.tvnow.de/impressum',
          onClickHandler: () => {},
        },
        {
          name: 'Privacy Center',
          href: '#',
          onClickHandler: (e: any) => {
            e.preventDefault();
            this.consentService
              .getSourcePointConsentService()
              .showPrivacyCenter();
          },
        },
        {
          name: 'Datenschutz',
          href: 'https://www.tvnow.de/datenschutz',
          onClickHandler: () => {},
        },
        {
          name: 'Nutzungsbedingungen',
          href: 'https://www.tvnow.de/agb',
          onClickHandler: () => {},
        },
        {
          name: 'Verträge hier kündigen',
          href: 'https://my.tvnow.de/mein-paket/vertraege-kuendigen',
          onClickHandler: (e: any) => {
            e.preventDefault();
            this.router
              .navigate(['/mein-paket/vertraege-kuendigen'])
              .then(() => {});
          },
        },
      ],
    },
    {
      title: 'Wir sind RTL±',
      links: [
        {
          name: 'Über RTL±',
          href: 'https://company.rtl.com/de//?utm_source=rtl&amp;utm_medium=owned&amp;utm_campaign=footer/',
          onClickHandler: () => {},
        },
        {
          name: 'Jobs',
          href: 'https://www.mediengruppe-rtl.de/jobs/',
          onClickHandler: () => {},
        },
        {
          name: 'Presse',
          href: 'https://kommunikation.mediengruppe-rtl.de/news/allemarken/uebersicht/',
          onClickHandler: () => {},
        },
        {
          name: 'Werben auf RTL±',
          href: 'https://www.ad-alliance.de/cms/portfolio/digital/portfolio.html#/digital/portfolio/rtlplus/profil/',
          onClickHandler: () => {},
        },
        {
          name: 'Newsletter',
          href: 'https://my.plus.rtl.de/newsletter',
          onClickHandler: (e: MouseEvent) => {
            e.preventDefault();
            this.router.navigate(['/newsletter']);
          },
        },
      ],
    },
  ];

  constructor(
    private readonly consentService: ConsentService,
    private readonly router: Router,
  ) {}
}
